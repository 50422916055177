import React from 'react';
import { Carousel,message,Tabs } from 'antd'; 
import { Link, graphql } from 'gatsby';   
import { navigate } from 'gatsby-link';

import ReactPlayer from 'react-player';
import SiteLayout from '@layouts/site-layout';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Video3 from '../../components/Video3';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const our_partner = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow:2, 
        }
      },
      {
         breakpoint: 480,
         settings: {
           slidesToShow:1, 
         }
       },
   ]
  };

  const { TabPane } = Tabs;

  function callback(key) {
    console.log(key);
  } 

  function onChange(a, b, c) {
    console.log(a, b, c);
  }
 

const wwo_slider = {
  arrows: true, 
  dots: false, 
};
  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}
 
 

export default class InfoPage extends React.Component { 
 
    constructor(props) {
        super(props)
        this.state = {
         school:'',
        contact:'', 
        email: ''
     }
    }

    onSubmit = e => {
        e.preventDefault()
      message.success( 'Thank you ! Your request has been send successfully')
    } 
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        e.target.setCustomValidity("")
    }

    handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                
                ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error))
    }
    render() {
        let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        e-learning software product that provides conceptual content delivery in a gamified format. Our
        team comes with deep experience and expertise gathered over many years of teaching and
        training students from various socio-economic strata of the country, preparing them for various
        reputed national and international competitive exams, and turning them into the best and
        brightest this country has ever seen.`
        let img = `img/stepapp_logo.png`
        return (
            <SiteLayout title="b2b" description={description} image={img}>   
                <div className="top_margin_comn"> 
                    <div className="b2b_banner">
                        <img src="img/b2b_banner.jpg" alt="" />
                        <span> <strong>STEP Skool</strong>  
                        <h3>Live Interactive Lectures for schools</h3>
                        <p>Well planned course structure, real time doubts solving, digital learning material and more!</p>
                        <Link to="/contact">Enquire Now</Link> 
                        </span>    
                    </div> 
                </div>
                
                {/* <div className="gamified_digital">
                    <h4 className="commn_heading">
                        <strong>Gamified digital learning for your school</strong>
                        <p></p>
                    </h4> 
                    <div className="bannsec_imgtext">
                        <div className="left_img">
                            <img src="img/gamify.png" alt="about image missing" />
                        </div>
                        <div className="right_text about_text">  
                            <p>Students learn faster and better with adaptive and personalised learning content delivered in a fun and enjoyable way. With STEPapp, the focus is on positive learning outcomes.</p>
                        </div>
                    </div>
                </div> */}


                {/* <img src="img/Doubts-Solved-In-Real-Time.png" />
                <img src="img/Mapped-To-School-Curriculum.png" />
                <img src="img/Chat-With-Teacher.png" />
                <img src="img/Comprehensive-Reporting.png" />
                <img src="img/Live-Quiz-With-Leaderboard.png" />
                <img src="img/Self-Paced-Learning-With-STEPapp.png" /> */}

                <div className="feature_tab">  
                <div className="container">  
                <h4 className="commn_heading">Features</h4> 
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane 
                        tab={
                            <div className="fet_tab_cont">
                            <span><img src="img/Live-Interactive-Classroom.png" /></span>
                            <strong> Live Interactive Classroom</strong>
                            </div>
                        }  key="1">
                        <div className="row fet_tab_sec"> 
                            <div className="col-md-6 col-sm-12"> 
                            <h3>Live Interactive Classroom</h3> 
                            <p>Switch from your physical classroom into the digital world that is interactive and which can be customized by the teacher to provide the best online class experience with cutting-edge technology. Lectures can be recorded and added to a knowledge bank which students can use for future reference or in case they missed a live lecture.</p>
                            </div>
                            <div className="col-md-6 col-sm-12"><img src="img/Live-Interactive-Classroom-image.png" /></div>
                        </div>
                        </TabPane>
                        <TabPane 
                        tab={
                        <div className="fet_tab_cont">
                            <span><img src="img/Live-Quiz-With-Leaderboard.png" /></span>
                            <strong> Live Quiz With Leaderboard </strong>
                         </div>
                        } key="2">
                        <div className="row fet_tab_sec"> 
                            <div className="col-md-6 col-sm-12"> 
                            <h3>Live Quiz With Leaderboard</h3> 
                            <p>No more boring one-sided classes, as teachers can engage students in class by taking live quiz sessions in ongoing lectures. The leaderboard stokes the competitive spirit and gives the teacher a larger view of class performance.</p>
                            </div>
                            <div className="col-md-6 col-sm-12"><img src="img/Live-Quiz-With-Leaderboard-image.png" /></div>
                        </div> 
                        </TabPane>
                        <TabPane 
                        tab={
                        <div className="fet_tab_cont">
                            <span><img src="img/Chat-With-Teacher.png" /></span>
                            <strong> Chat With Teacher </strong>
                         </div>
                        } key="3">
                        <div className="row fet_tab_sec"> 
                            <div className="col-md-6 col-sm-12"> 
                            <h3>Chat With Teacher</h3> 
                            <p>Teachers can personalise the students’ learning experience with the chat-with-teacher option, which students can use to ask doubts during an ongoing lecture.</p>
                            </div>
                            <div className="col-md-6 col-sm-12"><img src="img/Chat-With-Teacher-image.png" /></div>
                        </div> 
                        </TabPane>
                        <TabPane 
                        tab={
                        <div className="fet_tab_cont">
                            <span><img src="img/Doubts-Solved-In-Real-Time.png" /></span>
                            <strong> Doubts Solved In Real-Time </strong>
                         </div>
                        } key="4">
                        <div className="row fet_tab_sec"> 
                            <div className="col-md-6 col-sm-12"> 
                            <h3>Doubts Solved In Real-Time</h3> 
                            <p>Students can also share their doubts at any time with a pool of India’s top educationists and get them solved expertly and comprehensively.</p>
                            </div>
                            <div className="col-md-6 col-sm-12"><img src="img/Doubts-Solved-In-Real-Time-image.png" /></div>
                        </div> 
                        </TabPane>
                        <TabPane 
                        tab={
                        <div className="fet_tab_cont">
                            <span><img src="img/Self-Paced-Learning-With-STEPapp.png" /></span>
                            <strong> Self-Paced Learning With STEPapp </strong>
                         </div>
                        } key="5">
                        <div className="row fet_tab_sec"> 
                            <div className="col-md-6 col-sm-12"> 
                            <h3>Self-Paced Learning With STEPapp</h3> 
                            <p>STEPapp enables the students to control their learning pace by revising the concepts and relearning the topics.</p>
                            </div>
                            <div className="col-md-6 col-sm-12"><img src="img/Self-Paced-Learning-With-STEPapp-image.png" /></div>
                        </div>   
                        </TabPane>
                        <TabPane 
                        tab={
                        <div className="fet_tab_cont">
                            <span><img src="img/Mapped-To-School-Curriculum.png" /></span>
                            <strong> Mapped To School Curriculum </strong>
                         </div>
                        } key="6">
                        <div className="row fet_tab_sec"> 
                            <div className="col-md-6 col-sm-12"> 
                            <h3>Mapped To School Curriculum </h3> 
                            <p>Online classes and gamified content is tailored by a team of 400+ IITians & Doctors to match your school curriculum and accommodate different teaching styles.</p>
                            </div>
                            <div className="col-md-6 col-sm-12"><img src="img/Mapped-To-School-Curriculum-image.png" /></div>
                        </div>  
                        </TabPane>
                        <TabPane 
                        tab={
                        <div className="fet_tab_cont">
                            <span><img src="img/Comprehensive-Reporting.png" /></span>
                            <strong> Comprehensive Reporting</strong>
                         </div>
                        } key="7">
                        <div className="row fet_tab_sec"> 
                            <div className="col-md-6 col-sm-12"> 
                            <h3> Comprehensive Reporting  </h3> 
                            <p>Complete assessment of learning outcomes and test-readiness with a dashboard that assesses the performance of students when they use STEPapp Concepts.</p>
                            </div>
                            <div className="col-md-6 col-sm-12"><img src="img/Comprehensive-Reporting-image.png" /></div>
                        </div>   
                        </TabPane> 
                    </Tabs>  
                </div> 
                </div> 


                <div className="offer_section">
                <div className="container">    
                <h4 className="commn_heading">What we offer</h4> 
                <Carousel afterChange={onChange} {...wwo_slider}>
                    <div>
                    <div className="row wwo_slide_sec">
                    <div className="col-md-6 col-sm-12"><img src="img/Principals.png" /></div>
                    <div className="col-md-6 col-sm-12">
                        <div className="wwo_para"> 
                        <h3>Principals</h3>
                        <p>Comprehensive school level reporting via unique dashboards to keep track of the performance of your schools and school chains.</p>
                        </div>
                    </div> 
                    </div>
                    </div>
                     
                    <div>
                    <div className="row wwo_slide_sec">
                    <div className="col-md-6 col-sm-12"><img src="img/Teacher.png" /></div>
                    <div className="col-md-6 col-sm-12">
                    <div className="wwo_para"> 
                        <h3>Teachers</h3>
                        <p>Detailed real-time performance report of your classes with comprehensive learning profiles of your students through the teacher dashboard. Streaming facility to allow live lectures and digital classroom capability.</p>
                    </div> 
                    </div> 
                    </div>
                    </div>

                    <div>
                    <div className="row wwo_slide_sec">
                    <div className="col-md-6 col-sm-12"><img src="img/Parents.png" /></div>
                    <div className="col-md-6 col-sm-12">
                    <div className="wwo_para"> 
                        <h3>Parents</h3>
                        <p>Complete assessment of the learning outcomes of your child with detailed insights into their strengths and weaknesses. Their speed, accuracy and number of attempts all come into play. </p>
                    </div> 
                    </div>
                    </div>
                    </div>

                </Carousel> 
                </div>
                 </div>














                <div className="what_we_offer">  
                    <div className="container">  
                        <h4 className="commn_heading">STEPapp Dashboard</h4> 
                        <p className="comn_para">
                        Comprehensive reporting of learning outcomes at your fingertips</p>
                        <div className="row">
                            <div className="col-md-4">
                                <span><img src="img/dashboard-image-1.png" alt="" /></span>
                                <h3>Ensuring All Stakeholders Are In Sync</h3>
                                <p>Dashboards to keep all stakeholders (parents, teachers, and students) informed about the child’s progress and strengths at all times.</p>
                            </div>
                            <div className="col-md-4">
                                <span><img src="img/dashboard-image-2.png" alt="" /></span>
                                <h3>For Better Decision Making In Education </h3>
                                <p>Comprehensive 360-degree assessment of learning outcomes helps education policymakers to make informed decisions about the career and future of the students.</p>
                            </div>
                            <div className="col-md-4">
                                <span><img src="img/dashboard-image-3.png" alt="" /></span>
                                <h3> Quick Remedial Action </h3>
                                <p>Real-time data for maximizing learning outcomes in the form of conceptual clarity, speed and accuracy, and preparedness for competitive exams. </p>
                            </div>
                        </div> 
                    </div> 
                </div> 


        <div className="cont_form b2b_cont_form" 
        style={{ background: "url('img/b2b-better-learning.jpg') center left no-repeat"}} > 
         <div className="container">
            <div className="row">
               <div className="col-md-7">
                  <h3>Better Learning Outcomes For Your Students Await! </h3>
                  <p>Connect with us and empower your students with live Classes and Gamified Digital Learning </p>
               </div>
               <div className="col-md-5">
                  {/* <form>
                     <div className="form-row">
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Name" required />
                        </div>
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Email" required />
                        </div>
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Subject" required />
                        </div> 
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Phone Nnmber" required />
                        </div> 
                        <div className="form-group col-md-12"> 
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message here" required></textarea>
                        </div> 
                     </div>   
                     <button type="submit" className="btn btn-primary">Sign in</button>
                  </form> */}
                   
                            {/* <h1>Contact</h1> */}
                            <div className="form-row"> 
                            <form
                                name="contact"
                                method="post"
                                action="/contact/thanks/"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                onSubmit={this.handleSubmit}
                            >
                                <input type="hidden" name="form-name" value="contact" />
                                <div hidden>
                                    <label>
                                        Don’t fill this out:{' '}
                                        <input name="bot-field" onChange={this.handleChange} />
                                    </label>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'name'}>
                                    School Name
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input form-control"
                                            type={'text'}
                                            // required pattern="[A-Za-z]*"
                                            required  pattern="[A-z ']*"
                                            onInvalid={e => e.target.setCustomValidity("Enter Letters only")}
                                            name={'name'}
                                            onChange={this.handleChange}
                                            id={'name'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'Phone Number'}>
                                    Contact Number
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input form-control"
                                            required pattern="[0-9]*" 
                                            maxlength="10"
                                            type={'text'}
                                            name={'Phone Number'}
                                            onChange={this.handleChange}
                                            onInvalid={e => e.target.setCustomValidity(" Enter Numbers only")}
                                            id={'Phone Number'}
                                            required={true}
                                        />
                                    </div>
                                </div> 
                                <div className="field">
                                    <label className="label" htmlFor={'email'}>
                                        Email ID
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input form-control"
                                            required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            type={'email'}
                                            name={'email'}
                                            onChange={this.handleChange}
                                            id={'email'}
                                            required={true}
                                        />
                                    </div>
                                </div> 
                                 
                                <div className="field">
                                    <button className="button is-link btn btn-primary" type="submit">
                                        Enquire Now
                                    </button>
                        </div>
                     </form> 
                    </div>
               </div>
            </div>
         </div>
      </div> 





                {/* <div className="benefits_stepapp">  
                    <div className="container">  
                        <h4 className="commn_heading">Benefits of STEPapp For Your School</h4>
                        <div className="row">
                            <div className="col-md-6">
                            <ul>
                                <li>Track real-time progress with Principals’ Dashboard</li>
                                <li>Supporting tool for teachers</li>
                                <li>Learn from home solution</li>
                                <li>Streaming capability for providing live lectures </li>
                                <li>Can be used as a homework and revision tool</li>
                                <li>Gamified education keeps students focused</li>
                                <li>Enhancement in students’ performance</li>
                                <li>Micro & macro-level reports measuring students’ strengths and weaknesses</li> 
                            </ul>
                            </div>
                            <div className="col-md-6">
                                <img src="img/benifit.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>  */}
                
        {/* <div className="learning_outcomes"> 
            <div className="container">   
                <h4 className="commn_heading">Better Learning Outcomes For Your Students Await!</h4> <div className="b2b_cont">
                <form
                                name="b2b"
                                method="post"
                                action="/b2b/thanks/"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                onSubmit={this.handleSubmit}
                            >  
                             <input type="hidden" name="form-name" value="b2b" />
                                <div hidden>
                                    <label>
                                        Don’t fill this out:{' '}
                                        <input name="bot-field" onChange={this.handleChange} />
                                    </label>
                                </div>      
                             <div className="form-row">
                            
                        <div className="form-group col-md-12"> 
                           <input type="text" className="form-control" placeholder="Enter School Name *"  name="school" onChange={this.handleChange} required />
                        </div>
                        <div className="form-group col-md-12"> 
                           <input type="text" required pattern="[0-9]*" className="form-control" placeholder="Contact Number *" required />
                           <input type="text"   required pattern="[0-9]*" className="form-control" name="contact" placeholder="Contact Number *" onChange={this.handleChange} required />
                        </div>
                        <div className="form-group col-md-12"> 
                           <input type="email"  required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" className="form-control"  name="email" placeholder="Email Id" onChange={this.handleChange} required />
                        </div>   
                    <button type="submit" className="btn btn-primary">Enquire Now</button>
                   </div>    
                </form>
               </div>   
             </div>
           </div> */}
                
                
              <div className="impact">  
              <div className="container">  
                  <h4 className="commn_heading">Impact</h4>   
                    <div className="impt_text">
                        <p>Students in the most prestigious schools across the country have discovered the joy of digital classes and gamified learning. We have collaborated with   <strong className="color_org"> 2000+</strong> schools. </p>
                    </div>
                     <Carousel {...our_partner} className="row">
                        <div>
                            <div className="col-md-12">
                               <img src="img/p-1.jpg" alt="partner logo"/> 
                            </div>
                        </div>  
                        <div>
                            <div className="col-md-12">
                               <img src="img/p-2.jpg" alt="partner logo"/>
                            </div>
                        </div> 
                        <div>
                            <div className="col-md-12">
                               <img src="img/p-4.jpg" alt="partner logo"/>
                            </div>
                        </div>
                        <div>
                            <div className="col-md-12">
                               <img src="img/p-5.jpg" alt="partner logo"/>
                            </div>
                        </div>
                     </Carousel>  
               </div>
               </div>

         <div className="bkg_blue">  
            <div className="container">  
              <h4 className="commn_heading">Testimonials</h4> 
              <p className="comn_para">What our Students have to say about STEPapp</p>
              <Video3/>
                    {/* <Carousel {...our_partner} className="row patnar-slide our_team_sec">
                        <div>
                            <div className="col-md-12">
                                <div className="blog_text_area"> 
                                <ReactPlayer className="react_player" url='https://youtu.be/y-AxlATegLY'   width='100%' height='100%' controls='true' />
                                <h3>Reactions of students of school run by Chairman ICSE Board after playing STEPapp</h3>
                                </div>
                            </div>
                        </div>  

                            <div>
                            <div className="col-md-12">
                                    <div className="blog_text_area">
                                    <ReactPlayer className="react_player" url='https://youtu.be/oiO41WdfOw8' width='100%' height='100%' controls='true' />
                                        <h3>Reactions of students of Sulochanadevi Singhania School, Mumbai after playing STEPapp</h3> 
                                    </div>
                                </div>
                            </div>

                            <div>
                                    <div className="col-md-12">
                                    <div className="blog_text_area">
                                    <ReactPlayer className="react_player" url='https://youtu.be/2hx_WOmf-u8' width='100%' height='100%' controls='true' />
                                        <h3>Reactions of students of GD Somani School, Cuff Parade, Mumbai, after playing STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col-md-12">
                                <div className="blog_text_area">
                                <ReactPlayer className="react_player" url='https://youtu.be/9JQVgo9beW8' width='100%' height='100%' controls='true' />
                                    <h3>Feedback of Teacher of EMRS Khairi Parsoda on implementation of STEPapp</h3>
                                </div>
                            </div>
                        </div>

                            <div>
                                    <div className="col-md-12">
                                    <div className="blog_text_area">
                                    <ReactPlayer className="react_player" url='https://youtu.be/xwj9LI3USQg' width='100%' height='100%' controls='true' />
                                        <h3>Feedback of Principal of JNV Jalgaon on implementation of STEPapp</h3>
                                    
                                    </div>
                                </div>
                            </div>

                         <div>
                            <div className="col-md-12">
                                <div className="blog_text_area">
                                <ReactPlayer className="react_player" url='https://youtu.be/AlZlUA5EsdE' width='100%' height='100%' controls='true' />
                                    <h3>Mr Deepak S Parekh HDFC Bank Chairman</h3>
                                 
                                </div>
                            </div>
                        </div> 
                                    
                    </Carousel>   */}
                </div>
               </div>   
 

            </SiteLayout>
        )
    }
}
