import React, { Component } from 'react'
import { Carousel } from 'antd'; 
import { Modal, Button, Space } from 'antd';
import { Link, graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import SiteLayout from '@layouts/site-layout'; 
import ModalVideo from 'react-modal-video'


const our_partner = {
    dots: false,
    infinite: true,
    // autoplay:true,
    arrows:true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3, 
        }
      },
      {
         breakpoint: 640,
         settings: {
           slidesToShow: 2, 
         }
       },
       {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
        }
      },
   ]
  }; 


export class Video3 extends Component {
    state = { visible: false ,
        isOpen: false,
        videoId:'',
        playing:false
    };

    openModal = this.openModal.bind(this)
    onPlay = this.onPlay.bind(this)
   

  showModal = () => {
    this.setState({
      visible: true,
    }); 
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
};
onPlay (videoId) {
    this.setState({isOpen: true,
        videoId:videoId
  
    })
  }

 
  openModal (videoId) {
      console.log(videoId);
      
    this.setState({isOpen: true,
        playing:false,
                   videoId:videoId
                })
  }
 
    render() {
        return (
            <div>
                <div>
                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.videoId} onClose={() => this.setState({isOpen: false})} />
                    {/* <button onClick={this.openModal}>Open</button> */}
                    </div> 
                    <Carousel {...our_partner} className="row patnar-slide our_team_sec">
                        <div>
                            <div className="col-md-12">
                                <div className="blog_text_area"> 
                                {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='y-AxlATegLY' onClose={() => this.setState({isOpen: false})} /> */}
                                {/* <button onClick={()=>this.openModal('y-AxlATegLY')}>  */}
                                <ReactPlayer className="react_player" 
                                 url={this.state.isOpen === false ? 'https://youtu.be/mZ6Hux8HJBY': ''}  
                                  onPlay={() => this.onPlay('mZ6Hux8HJBY')} 
                                  width='100%' height='100%' controls='false'
                                //  light={true}
                                //   playIcon={<button onClick={()=>this.openModal('y-AxlATegLY')}>Open</button> }
                                  />
                                  {/* <img src="img/p3.png" alt="" /> */}
                                <h3>STEPapp | Anjuman E Islam, Mumbai</h3>
                                {/* </button> */}
                                </div>
                            </div>
                        </div>  

                            <div>
                            <div className="col-md-12">
                                    <div className="blog_text_area">
                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='oiO41WdfOw8' onClose={() => this.setState({isOpen: false})} /> */}
                                   {/* <button onClick={()=>this.openModal('oiO41WdfOw8')}>Open</button> */}
                                    <ReactPlayer className="react_player" 
                                     url={this.state.isOpen === false ? 'https://youtu.be/ONKs1apM2tA': ''}  
                                      width='100%' height='100%' controls='true'  
                                      onPlay={() => this.onPlay('ONKs1apM2tA')}
                                      />
                                        <h3>STEPapp | New Delhi Municipal Council (NDMC)</h3> 
                                    </div>
                                </div>
                            </div>
                            <div>
                            <div className="col-md-12">
                                    <div className="blog_text_area">
                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='oiO41WdfOw8' onClose={() => this.setState({isOpen: false})} /> */}
                                   {/* <button onClick={()=>this.openModal('oiO41WdfOw8')}>Open</button> */}
                                    <ReactPlayer className="react_player" 
                                     url={this.state.isOpen === false ? 'https://youtu.be/y-AxlATegLY': ''}  
                                      width='100%' height='100%' controls='true'  
                                      onPlay={() => this.onPlay('y-AxlATegLY')}
                                      />
                                        <h3>Reactions of students of school run by Chairman ICSE Board after playing STEPapp</h3> 
                                    </div>
                                </div>
                            </div>

        
                    </Carousel> 
                
            </div>
        )
    }
}

export default Video3
